import { Link, Outlet, useLoaderData } from '@remix-run/react';
import { externalAction, externalLoader } from '~/utils/externalLoader.server';
export const action = externalAction;
export const loader = externalLoader;

export default function Layout() {
	return (
		<>
			<Outlet />
		</>
	);
}
